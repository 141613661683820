<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <!-- <people-details-drawer></people-details-drawer> -->
    <v-row dense>
      <v-col>
        <div class="d-flex align-center my-4">
          <div class="text-h5 text-color-main font-weight-black">
            {{ title }}
          </div>
          <div class="field-width">
            <v-select
              :items="items"
              item-text="text"
              v-model="selectedItem"
              solo
              class="ml-8 mr-4 rounded-lg"
              dense
              hide-details
              flat
            >
            </v-select>
          </div>
          <div class="field-width">
            <v-text-field
              v-model="search"
              outlined
              dense
              hide-details
              placeholder="Quick Search"
              class="rounded-lg field-width"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="bg-secondary text-color-white"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Export
              </v-btn>
            </template>
            <v-list dense class="cursor-pointer">
              <v-list-item v-for="(item, key) in exportItems" :key="key">
                <v-list-item-title @click="exportSelected(item.value)">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row dense></v-row>
    <v-row dense>
      <v-col>
        <people-table @setSelected="setSelected($event)"></people-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const PeopleTable = () => import("../tables/PeopleTable");
// const PeopleDetailsDrawer = () => import("../drawers/PeopleDetailsDrawer");

export default {
  components: {
    PeopleTable
    // PeopleDetailsDrawer
  },
  data: () => ({
    drawer: true,
    title: "People",
    items: [
      { text: "All Members" },
      { text: "Profile" },
      { text: "Notes" },
      { text: "Directory" },
      { text: "Individuals" },
      { text: "Families" },
      { text: "Significant Events" }
    ],
    selectedItem: "All Members",
    search: "",
    exportItems: [
      { text: "Export to CSV", value: "csv" },
      { text: "Export to PDF", value: "pdf" }
    ],
    selected: []
  }),
  methods: {
    exportSelected(value) {
      console.log({ value }, this.selected);
    },
    setSelected(value) {
      console.log({ value });
      this.selected = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.field-width {
  width: 200px;
}
</style>
